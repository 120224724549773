.admin__auth__wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url(../../../public/images/bg.png);
  background-position: bottom;
  background-size: cover;
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin__auth__logo {
  margin: 20px auto;
  width: 100%;
  height: 240px;
}

.admin__login__card__col {
  margin-bottom: 30px;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 768px) {
  .admin__auth__logo {
    height: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .admin__auth__content {
    width: 98%;
  }
  .admin__auth__logo {
    margin: 0px auto;
    height: 325px;
  }
  .admin__login__card__col {
    margin-bottom: 7px;
  }
}
@media screen and (min-width: 1600px) {
  .admin__auth__content {
    width: 75%;
  }
}
