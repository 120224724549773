.profile__avatar {
  border: 5px solid #6c5ce4;
  border-radius: 50%;
  padding: 5px;
}

.profile__details {
  border-right: none;
  border-top: 2px solid rgba(5, 5, 5, 0.06);
  padding-right: 8px !important;
}

.profile__divider {
  display: none;
  margin: 0;
}

.profile__get__back {
  margin-top: 10px;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 580px) {
  .profile__get__back {
    margin-top: 0;
    margin-left: 10px;
  }
}
@media screen and (min-width: 768px) {
  .profile__details {
    border-right: 2px solid rgba(5, 5, 5, 0.06);
    border-top: none;
    padding-right: 18px !important;
  }
  .profile__divider {
    display: block;
  }
}
