.faq__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4vh;
}

.faq__collapse .ant-collapse-content-box p {
  color: #49465f;
}
