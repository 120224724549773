.home__content {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px auto;
  color: white;
}

.home__game {
  text-align: center;
  padding: 0;
  border-radius: 15px;
  margin: 5vh auto;
  -webkit-box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
}

.home__game__button {
  width: 100%;
  height: 30vw;
}

.home__game__button img {
  width: 15vw;
  padding: 2vw 0;
}

.home__game__button svg {
  font-size: 5vw;
}

.home__game__button__title {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.home__game__button__title span:first-child {
  font-size: 5.7vw;
  line-height: 3vw;
}

.home__game__button__title span:last-child {
  font-size: 4.5vw;
}

.responsive-h1 {
  font-size: 28px !important;
}

.responsive-h2 {
  font-size: 22px !important;
}

.disabled-payment-btn {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.88) !important;
  opacity: 0.7;
}

/***********************************Mobile View********************************************/
@media screen and (min-width: 425px) {
  .home__game__button {
    width: 90%;
    height: 25vw;
  }

  .home__game__button img {
    width: 12vw;
  }

  .home__game__button svg {
    font-size: 3.5vw;
  }

  .home__game__button__title span:first-child {
    font-size: 4.8vw;
  }

  .home__game__button__title span:last-child {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) {
  .responsive-h1 {
    font-size: 38px !important;
  }

  .responsive-h2 {
    font-size: 30px !important;
  }

  .home__game__button {
    width: 75%;
    height: 15vw;
  }

  .home__game__button img {
    width: 8vw;
  }

  .home__game__button svg {
    font-size: 3vw;
  }

  .home__game__button__title span:first-child {
    font-size: 3vw;
  }

  .home__game__button__title span:last-child {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 1440px) {
  .home__game__button {
    width: 60%;
    height: 12vw;
  }

  .home__game__button img {
    width: 5.5vw;
  }

  .home__game__button svg {
    font-size: 2vw;
  }

  .home__game__button__title span:first-child {
    font-size: 2.7vw;
  }

  .home__game__button__title span:last-child {
    font-size: 2.2vw;
  }
}

/* ================= Tab styles ================== */
.form-row {
  width: 70%;
  float: left;
  background-color: #ededed;
}
#card-element {
  background-color: transparent;
  height: 40px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

#card-element--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

#card-element--invalid {
  border-color: #fa755a;
}

#card-element--webkit-autofill {
  background-color: #fefde5 !important;
}

#submitbutton,
#tap-btn {
  align-items: flex-start;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgb(50, 50, 93);
  background-image: none;
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 255, 255);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 255, 255);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 255, 255);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: none;
  border-top-width: 0px;
  box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 15px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 600;
  height: 35px;
  letter-spacing: 0.375px;
  line-height: 35px;
  margin-bottom: 0px;
  margin-left: 12px;
  margin-right: 0px;
  margin-top: 28px;
  outline-color: rgb(255, 255, 255);
  outline-style: none;
  outline-width: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 0px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 0px;
  text-align: center;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease;
  white-space: nowrap;
  width: 150.781px;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-border-image: none;
}
