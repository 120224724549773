.footer__wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: #d8d8f6;
  padding: 0;
  font-size: 20px;
  box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
}

.footer_contact {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background-color: #6c5ce4;
  padding: 5px 0;
  text-align: center;
}

.contact__info h5,
.contact__info h4 {
  color: white;
}

.contact__info h5:hover {
  color: #d8d8f6;
}

.footer_info {
  width: 95%;
  margin: 0 auto;
  text-align: center;
}

.footer_logo {
  width: 210px;
}

.footer_store {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.footer_store img {
  width: 100px;
}

#app_store {
  width: 103px;
}

.footer_links {
  padding: 20px 0;
  text-align: center;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.footer_links a {
  color: black;
  font-size: 20px;
  position: relative;
  text-decoration: none;
}

.footer_links a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background-color: #6c5ce4;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  margin: -6px 0;
}

.footer_links a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.admin_footer_rights {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  width: 95%;
  text-align: center;
}

.footer_rights {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 95%;
  border-top: 1px solid black;
  margin: 0 auto 9px auto;
  padding: 0px 10px;
  text-align: center;
}

.footer_rights a {
  display: inline-block;
  color: black;
  font-size: 14px;
  margin: 0px 10px;
}
.footer_rights a:hover {
  text-decoration: underline;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 300px) {
  .footer_logo {
    width: 295px;
  }
  .footer_store img {
    width: 142px;
  }
  #app_store {
    width: 153px;
  }
}
@media screen and (min-width: 992px) {
  .footer_info {
    text-align: right;
  }
  .footer_store {
    justify-content: flex-start;
  }
  .footer_links {
    text-align: right;
    border-top: none;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .footer_rights {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
}
