.question__content__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4vh;
}

.question__header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.question__counter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question_image {
  text-align: center;
}

.question_image img {
  width: 90%;
  margin: 20px 0;
}

.question__submit__button {
  width: 100%;
  background-color: #e6e6e6;
  color: #6a5ae0;
}
.question__submit__button:hover {
  color: #6a5ae0;
  background-color: #f0f0f0;
}
.question__submit__button:focus {
  color: #6a5ae0;
  background-color: #d6d4d4;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 300px) {
  .question__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
  }
}

@media screen and (min-width: 768px) {
  .question_image img {
    width: 50%;
    height: 30vh;
  }
  .question__submit__button {
    width: 60%;
  }
}

@media screen and (min-width: 992px) {
  .question_image img {
    width: 35%;
  }
  .question__submit__button {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .question_image img {
    width: 30%;
  }
}
