.auth__wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url(../../../public/images/bg.png);
  background-position: bottom;
  background-size: cover;
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth__logo {
  margin: 20px auto;
  width: 100%;
  height: 240px;
}

.login__video {
  width: 220px;
  height: 130px;
}

.login__card__col {
  margin-bottom: 30px;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 300px) {
  .login__video {
    width: 320px;
    height: 180px;
  }
}
@media screen and (min-width: 768px) {
  .auth__logo {
    height: 400px;
  }
  .login__video {
    width: 600px;
    height: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .auth__content {
    width: 98%;
  }
  .auth__logo {
    margin: 0px auto;
    height: 300px;
  }
  .login__video {
    width: 530px;
    height: 310px;
  }
  .login__card__col {
    margin-bottom: 7px;
  }
}
@media screen and (min-width: 1600px) {
  .auth__content {
    width: 75%;
  }
}
