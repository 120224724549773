@font-face {
  font-family: "DIN Next LT Arabic";
  src: url("../../configs/fonts/DINNextLTArabic-Regular.woff2") format("woff2"),
    url("../../configs/fonts/DINNextLTArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circle Book";
  src: url("../../configs/fonts/Circle-Book.woff2") format("woff2"),
    url("../../configs/fonts/Circle-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circle";
  src: url("../../configs/fonts/Circle-Light.woff2") format("woff2"),
    url("../../configs/fonts/Circle-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --primary-color: #6a5ae0;
}

* {
  margin: 0;
  padding: 0;
  font-family: "DIN Next LT Arabic", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  margin: 3rem auto;
}

.flex {
  display: flex;
}

.range {
  flex: auto;
  margin-right: 20px;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.text-center {
  text-align: center;
}

.flex-fill {
  flex: 0 0 100%;
}

.fill {
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.loading__icon svg {
  font-size: 120px !important;
}

.ant-tooltip .ant-tooltip-arrow,
.ant-popover .ant-popover-arrow {
  display: none;
}
.ant-tooltip .ant-tooltip-inner {
  border-radius: 7px !important;
}
