.details__content__wrapper {
  width: 95%;
  margin: 3vh auto;
}

.details__content__image {
  width: 100%;
}

.details__info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeefc;
}

.info__rightBorder {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 1px solid #cccccc;
}

.info__leftBorder {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.details__info img {
  width: 40px;
  margin: 5px;
}

.details__next {
  text-align: center;
}

.details__next button {
  width: 70%;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 425px) {
  .details__content__image {
    width: 70vw;
  }
  .details__next button {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .details__content__image {
    width: 40vw;
  }
}

@media screen and (min-width: 992px) {
  .details__content__image {
    width: 100%;
  }
  .details__next button {
    width: 70%;
  }
}
