.login__props {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 1200px) {
  .login__card .ant-card-body {
    padding-bottom: 10px;
    padding-top: 15px;
  }
  .login__card .ant-form-item {
    margin-bottom: 19px;
  }
}
