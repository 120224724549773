.tests__content__wrapper {
  width: 90%;
  margin: 0 auto 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tests__filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
}

.tests__card {
  overflow: hidden;
  width: 95%;
  margin: 0 auto;
}

.tests__table .ant-pagination-item a {
  color: white;
}
.tests__table .ant-pagination-item-active a {
  color: #6a5ae0;
}
.tests__table .ant-pagination-next svg,
.tests__table .ant-pagination-prev svg {
  color: white;
}
.tests__table .ant-pagination-disabled svg {
  color: black;
  opacity: 0.5;
}
/***********************************Mobile View********************************************/
@media screen and (max-width: 576px) {
  .tests__table .ant-pagination .ant-pagination-options {
    display: block;
  }
}
