.layout__header {
  height: auto;
  width: 97vw;
  margin: 0px auto;
  margin-bottom: 0.7rem;
  -webkit-box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.46);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #d8d8f6;
  display: flex;
  align-items: center;
  padding-inline: 15px;
}

.header__logo {
  display: flex;
  margin-top: 2px;
  width: 150px;
}

.header__logo img {
  width: 100%;
}

.header__user,
.header__menu {
  height: 100px;
}

.layout__header .ant-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__menu {
  color: #6c5ce4;
  background-color: transparent;
  display: none;
}

.header__user {
  display: none;
  justify-content: flex-end;
  align-items: center;
}

.header__username {
  color: #6c5ce4;
  margin-inline: 24px;
}

.header__username:hover {
  color: #008f61;
}

.header__username:active {
  color: #d34e87;
}

.header__dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
}

.header__menu .ant-menu-item {
  display: flex;
  align-items: center;
}

.header__menu .ant-menu-submenu {
  top: 10px !important;
}

.header__menu .ant-menu-item::after {
  bottom: -15px !important;
}

.header__menu .ant-menu-submenu::after {
  bottom: -9px !important;
}

.header__menu .ant-menu-item:hover,
.header__menu .ant-menu-submenu svg:hover {
  color: #008f61 !important;
}

.header__menu .ant-menu-item:hover::after,
.header__menu .ant-menu-submenu:hover::after {
  border-bottom-color: #008f61 !important;
}

.header__menu .ant-menu-item-selected,
.header__menu .ant-menu-item-selected:hover {
  color: #d34e87 !important;
}

.header__menu .ant-menu-item-selected::after,
.header__menu .ant-menu-item-selected:hover::after {
  border-bottom-color: #d34e87 !important;
}
/***********************************Mobile View********************************************/
@media screen and (min-width: 768px) {
  .header__menu {
    width: 100%;
  }

  .header__menu a,
  .header__menu svg {
    font-size: 24px;
  }

  .header__username {
    font-size: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .header__username {
    font-size: 32px;
  }
}

@media screen and (min-width: 1099px) {
  .header__menu {
    display: flex;
    align-items: center;
  }

  .header__user {
    display: flex;
  }

  .header__dropdown {
    display: none;
  }
}
