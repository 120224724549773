.about__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4vh;
}

.about__content li {
  line-height: 38px;
}
